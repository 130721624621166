import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { GetTokenFunc } from '../utils/GetToken';

export const useGetBlogs = () => {
  return useQuery({
    queryKey: ['userBlogs'],
    queryFn: async () => {
      return await axios.get('api/v1/blog', {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

export const useGetArticals = () => {
  return useQuery({
    queryKey: ['userarticals'],
    queryFn: async () => {
      return await axios.get('api/v1/get-articals');
    },
  });
};

export const useGetNews = () => {
  return useQuery({
    queryKey: ['userNews'],
    queryFn: async () => {
      return await axios.get('api/v1/get-news', {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};
