import React, { useRef } from 'react';
import { Paper, Box, Typography, Button } from '@mui/material';
import * as Styles from '../../Common/Styles.js';
import { useGetArticals } from '../../Hooks/blogHooks.js';
import forwardicon from '../../Assets/mainPage/forwardicon.svg';
import backwardicon from '../../Assets/mainPage/backwardicon.svg';
import slideone from '../../Assets/mainPage/slideone.svg';
import slidetwo from '../../Assets/mainPage/slidetwo.svg';
import newssec from '../../Assets/mainPage/newssec.svg';
import Fade from 'react-reveal/Fade';
import { useNavigate, useLocation } from 'react-router-dom';
const OpinionComponent = () => {
  const scrollBoxRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { data: articals } = useGetArticals();
  const scrollForward = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({ left: 500, behavior: 'smooth' }); // Adjust the scroll amount as needed
    }
  };

  const scrollBackward = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({ left: -500, behavior: 'smooth' }); // Adjust the scroll amount as needed
    }
  };
  return (
    <div>
      <Box
        sx={{
          width: '100%',
          // background: '#CEE8EE',
          background: 'rgba(247, 249, 251, 1)',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          mt: 10,
          p: 2,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            width: '80%',
            mx: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Fade top duration={500}>
            <Typography sx={Styles.boxMainText}>Latest opinion</Typography>
          </Fade>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <Box
              component={'img'}
              src={backwardicon}
              sx={{
                height: 'auto',
                width: 'auto',
                cursor: 'pointer',
                boxShadow: 3,
                transition: 'transform 0.3s, box-shadow 0.3s',
                borderRadius: '10px',
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: 6,
                },
              }}
              onClick={scrollBackward}
            />
            <Box
              component={'img'}
              src={forwardicon}
              sx={{
                height: 'auto',
                width: 'auto',
                cursor: 'pointer',
                borderRadius: '10px',
                boxShadow: 3,
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  // transform: 'scale(1.02)',
                  boxShadow: 6,
                },
              }}
              onClick={scrollForward}
            />
          </Box>
        </Box>
        <Box sx={scrollingImg} ref={scrollBoxRef}>
          {articals?.data?.map((item, index) => (
            <>
              <Fade top duration={500}>
                <Box
                  key={index}
                  sx={{
                    position: 'relative',
                    display: 'inline-block',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    navigate(`/viewnews`, {
                      state: {
                        title: item?.title,
                        overView: item?.overView,
                        image: item?.image,
                        analysis: item?.analysis,
                        keyInsights: item?.keyInsights,
                      },
                    });
                  }}
                >
                  <Box
                    component={'img'}
                    src={item?.image}
                    alt=""
                    sx={bigimageStyles}
                  />
                  <Box sx={newsBoxText}>
                    <Typography
                      sx={{
                        ...Styles.boxMainText,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        WebkitLineClamp: 1, // Limits the text to 2 lines
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item?.title}
                    </Typography>
                    <Typography
                      sx={{
                        ...Styles.boxSubText,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        WebkitLineClamp: 2, // Limits the text to 2 lines
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.overView?.replace(/<\/?[^>]+(>|$)/g, '')}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '10px',
                    mt: 3,
                  }}
                >
                  {/* <Box sx={subBoxStyle}>
                    <Box
                      component={'img'}
                      src={newssec}
                      sx={{
                        height: 'auto',
                        maxWidth: '150px',
                        borderRadius: '10px',
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        justifyContent: 'space-around',
                      }}
                    >
                      <Typography
                        sx={{ ...Styles.gridheaderText, textAlign: 'left' }}
                      >
                        5 reasons why you should{' '}
                      </Typography>
                      <Typography
                        sx={{
                          ...Styles.boxSubText,
                          whiteSpace: 'normal',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 4,
                          WebkitBoxOrient: 'vertical',
                          color: 'rgba(62, 50, 50, 0.75)',
                        }}
                      >
                        So, you finally went to your first boxing class and
                        learned the basics of the sport. You also learned that
                        it’s recommended to wrap your hands before putting on
                        the gloves. But there are times when you just don’t feel
                        like wrapping them and you wonder why you even need
                        them. Well, this blog is going to explain the benefits
                        of wrapping your hands.
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={subBoxStyle}>
                    <Box
                      component={'img'}
                      src={newssec}
                      sx={{
                        height: 'auto',
                        maxWidth: '150px',
                        borderRadius: '10px',
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        justifyContent: 'space-around',
                      }}
                    >
                      <Typography
                        sx={{ ...Styles.gridheaderText, textAlign: 'left' }}
                      >
                        5 reasons why you should{' '}
                      </Typography>
                      <Typography
                        sx={{
                          ...Styles.boxSubText,
                          whiteSpace: 'normal',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 4,
                          WebkitBoxOrient: 'vertical',
                          color: 'rgba(62, 50, 50, 0.75)',
                        }}
                      >
                        So, you finally went to your first boxing class and
                        learned the basics of the sport. You also learned that
                        it’s recommended to wrap your hands before putting on
                        the gloves. But there are times when you just don’t feel
                        like wrapping them and you wonder why you even need
                        them. Well, this blog is going to explain the benefits
                        of wrapping your hands.
                      </Typography>
                    </Box>
                  </Box> */}
                  {/* <Box
                    component={'img'}
                    src={slidetwo}
                    alt=""
                    sx={smallimgStyle}
                  />{' '}
                  <Box
                    component={'img'}
                    src={slidetwo}
                    alt=""
                    sx={smallimgStyle}
                  /> */}
                </Box>
              </Fade>
            </>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default OpinionComponent;

const scrollingImg = {
  mt: 2,
  height: 'auto',
  width: '100%',
  mx: 'auto',
  maxWidth: { xl: '80%', lg: '80%', md: '80%', sm: '100%', xs: '100%' },
  overflowX: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '20px',
  whiteSpace: 'nowrap',
};

const bigimageStyles = {
  maxHeight: {
    xl: '380px',
    lg: '380px',
    md: '340px',
    sm: '210px',
    xs: '210px',
  },
  ml: 1,
  Width: 'auto',
  height: 'auto',
  borderRadius: '10px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.01)',
  },
  // transition: 'border-color 0.5s ease-in-out',
  // border: '2px solid transparent',
  // borderRadius: '12px',
  // '&:hover': {
  //   borderColor: 'primary.main', // Change this color as needed
  // },
};

const smallimgStyle = {
  maxHeight: {
    xl: '200px',
    lg: '200px',
    md: '180px',
    sm: '120px',
    xs: '120px',
  },
  width: 'auto',
  height: 'auto',
  borderRadius: '10px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.01)',
  },
};

const newsBoxText = {
  backgroundColor: '#E8E8E7',
  position: 'absolute',
  bottom: '25px',
  left: '10px',
  color: '#000',
  padding: '10px 10px',
  borderRadius: '10px',
  width: '95%',
  mx: 'auto',
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  opacity: 0.9,
};

const subBoxStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  p: 1,
  background: '#fff',
  borderRadius: '10px',
  minWidth: '400px',
  // boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.01)',
  },
};
