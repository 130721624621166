import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  Grid,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  Fade,
  Select,
} from '@mui/material';
import ReactGA from 'react-ga';
import sms from '../../Assets/LoginSignup/sms.png';
import lock from '../../Assets/LoginSignup/lock.png';
import googleimg from '../../Assets/LoginSignup/googleimg.png';
import user from '../../Assets/LoginSignup/user.png';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import mainLogo from '../../Assets/Header/mainLogo.png';
import { Link, useNavigate } from 'react-router-dom';
import menuHorz from '../../Assets/Header/menuHorz.png';
import phone from '../../Assets/Header/phone.png';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import pricetag from '../../Assets/Header/pricetag.png';
import setting from '../../Assets/Header/setting.png';
import help from '../../Assets/Header/help.png';
import num from '../../Assets/LoginSignup/num.svg';
import ipimg from '../../Assets/LoginSignup/ipimg.svg';
import gglreg from '../../Assets/LoginSignup/gglreg.svg';
import paralogo from '../../Assets/Header/paralogo.svg';
import cityimg from '../../Assets/LoginSignup/cityimg.svg';
import * as Styles from '../../Common/Styles.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSignUp, useGooleLoginAuth } from '../../Hooks/useSignUp';
import cookie from 'js-cookie';

import axios from 'axios';
const SignUp = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [profession, setProfession] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [ipadd, setIPadd] = useState([]);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const {
    mutate: GoogleLoginMutate,
    isLoading: GoogleLoading,
    isError: GoogleError,
  } = useGooleLoginAuth();

  const login = useGoogleLogin({
    onSuccess: (res) => {
      GoogleLoginMutate(res.code, {
        onSuccess: (response) => {
          cookie.set('Bearer', response.data.token);
          navigate('/mainchat/:id');
        },
        onError: (error) => {
          return;
        },
      });
    },
    onError: (error) => console.log('err', error),
    flow: 'auth-code',
  });

  // sign up integration

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(
      z
        .object({
          fullName: z.string().min(3),
          email: z.string().email(),
          password: z.string().min(8),
          city: z.string().min(3),
          mobile: z.string().min(10).max(10),
          profession: z.string().min(),
          proof: z.instanceof(FileList).optional(),
          ipaddress: z.string(),
        })
        .refine(
          (data) => {
            if (data.profession === 'student') {
              return data.email.endsWith('.ac') || data.email.endsWith('.edu');
            }
            return true;
          },
          {
            message: 'Student email must end with .ac or .edu',
            path: ['email'],
          }
        )
    ),
  });

  useEffect(() => {
    if (watch('proof')) {
      const namesArray = Array.from(watch('proof')).map((file) => file?.name);
      setFileNames(namesArray);
    }
  }, [watch('proof')]);

  useEffect(() => {
    // Reset ipaddress when profession changes
    if (profession !== 'student') {
      setValue('ipaddress', '');
      setIPadd('');
    }
  }, [profession, setValue]);

  const handleProfessionChange = (e) => {
    const value = e.target.value;
    setProfession(value);
    setValue('profession', value);
  };

  const handleIPaddChange = (e) => {
    const value = e.target.value;
    setIPadd(value);
    setValue('ipaddress', value);
  };

  const { mutate: SignUpMutate, isLoading: SignUpLoading, error } = useSignUp();
  const SignUpSubmit = handleSubmit((data) => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'SignUp Button',
      value: 1,
    });
    const formData = new FormData();
    for (const key in data) {
      if (key === 'proof' && data[key] && profession === 'student') {
        Array.from(data[key]).forEach((file) => {
          formData.append(key, file);
        });
      } else if (key !== 'proof' || profession === 'student') {
        formData.append(key, data[key]);
      }
    }

    SignUpMutate(formData, {
      onSuccess: (response) => {
        if (response.status === 201) {
          cookie.set('Bearer', response.data.token);
          navigate('/mainchat/:id');
        }
      },
      onError: (error) => {
        toast.error('Registration failed. Please try again.', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      },
    });
  });

  return (
    <Paper elevation={0} sx={Styles.PaperCommonDesReg}>
      <Box
        component={'img'}
        src={paralogo}
        alt=""
        onClick={() => {
          navigate('/');
        }}
        sx={{
          height: '40px',
          width: '40px',
          cursor: 'pointer',
          m: 1,
          display: {
            xl: 'flex',
            lg: 'flex',
            md: 'flex',
            sm: 'flex',
            xs: 'none',
          },
        }}
      />
      <Box
        sx={{
          display: {
            xl: 'none',
            lg: 'none',
            md: 'none',
            sm: 'none',
            xs: 'flex',
          },
        }}
      >
        <header style={headerStyle}>
          <Box sx={{}}>
            <Box
              onClick={() => {
                navigate('/');
              }}
              component={'img'}
              src={paralogo}
              alt=""
              sx={{
                height: 'auto',
                maxWidth: 'auto',
                cursor: 'pointer',
                m: 1,
                transition: '0.5s',
              }}
            />
          </Box>
        </header>
      </Box>
      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={Styles.PaperGrid}
        >
          <Paper elevation={1} sx={Styles.paperBack}>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/login');
              }}
            >
              <Typography
                sx={{
                  ...Styles.signUp,
                  fontSize: {
                    xl: '14px',
                    lg: '14px',
                    md: '12px',
                    sm: '8px',
                    xs: '8px',
                  },
                  transform: 'rotate(6deg)',
                  textAlign: 'right',
                  width: '85%',
                }}
              >
                Sign In
              </Typography>
            </Box>
            <Paper elevation={2} sx={{ ...Styles.paperDes, p: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  mt: { xl: 1, lg: 1, md: 1, sm: 2, xs: 0 },
                }}
              >
                <Typography sx={Styles.welText}>Sign Up Here!</Typography>{' '}
              </Box>
              <form onSubmit={SignUpSubmit}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    width: {
                      xl: '80%',
                      lg: '80%',
                      md: '80%',
                      xs: '95%',
                      sm: '95%',
                    },
                    mx: 'auto',
                    gap: {
                      xl: '10px',
                      lg: '10px',
                      md: '10px',
                      sm: '10px',
                      xs: '5px',
                    },
                  }}
                >
                  <Typography sx={Styles.subText}>Full Name</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      mx: 'auto',
                    }}
                  >
                    <Input
                      id="standard-basic"
                      variant="outlined"
                      disableUnderline
                      placeholder="Rahul Sharma"
                      {...register('fullName')}
                      sx={{
                        ...Styles.InputFieldDes,
                        borderColor: isInputFocused
                          ? 'primary.main'
                          : '#E0E0E0',
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box
                            component="img"
                            src={user}
                            sx={Styles.iconStyle}
                          />
                        </InputAdornment>
                      }
                      inputProps={{
                        style: {
                          color: '#797979',
                          fontSize: '14px',
                          padding: '4px',
                          paddingLeft: '10px', // Adjust the left padding to make room for the icon
                        },
                      }}
                    />
                    <Typography sx={Styles.validationText}>
                      {errors.fullName?.message}
                    </Typography>
                  </Box>
                  <Typography sx={Styles.subText}>Email</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      mx: 'auto',
                    }}
                  >
                    <Input
                      id="standard-basic"
                      variant="outlined"
                      disableUnderline
                      {...register('email')}
                      placeholder="Email"
                      sx={Styles.InputFieldDes}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box
                            component="img"
                            src={sms}
                            sx={Styles.iconStyle}
                          />
                        </InputAdornment>
                      }
                      inputProps={{
                        style: {
                          color: '#797979',
                          fontSize: '14px',
                          padding: '4px',
                          paddingLeft: '10px', // Adjust the left padding to make room for the icon
                        },
                      }}
                    />
                    <Typography sx={Styles.validationText}>
                      {errors.email?.message}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      gap: '20px',
                      justifyContent: 'center',
                    }}
                  >
                    <Box>
                      <Typography sx={Styles.subText}>Mobile </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          mx: 'auto',
                          // mt: 0.5,
                        }}
                      >
                        <Input
                          id="standard-basic"
                          variant="outlined"
                          disableUnderline
                          {...register('mobile')}
                          placeholder="8989836453"
                          sx={Styles.InputFieldDes}
                          startAdornment={
                            <InputAdornment position="start">
                              <Box
                                component="img"
                                src={num}
                                sx={{ ...Styles.iconStyle, width: '25px' }}
                              />
                            </InputAdornment>
                          }
                          inputProps={{
                            style: {
                              color: '#797979',
                              fontSize: '14px',
                              padding: '7px',
                              paddingLeft: '10px', // Adjust the left padding to make room for the icon
                            },
                          }}
                        />
                      </Box>
                      <Typography sx={Styles.validationText}>
                        {errors.mobile?.message}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={Styles.subText}>City </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          mx: 'auto',
                          mt: 0.2,
                        }}
                      >
                        <Input
                          id="standard-basic"
                          variant="outlined"
                          disableUnderline
                          {...register('city')}
                          placeholder="City"
                          sx={Styles.InputFieldDes}
                          startAdornment={
                            <InputAdornment position="start">
                              <Box
                                component="img"
                                src={cityimg}
                                sx={Styles.iconStyle}
                              />
                            </InputAdornment>
                          }
                          inputProps={{
                            style: {
                              color: '#797979',
                              fontSize: '14px',
                              padding: '4px',
                              paddingLeft: '10px', // Adjust the left padding to make room for the icon
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      gap: '20px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      sx={{
                        width: profession === 'student' ? '165px' : '100%',
                      }}
                    >
                      <Typography sx={Styles.subText}>Profession</Typography>
                      <Select
                        sx={{
                          ...Styles.opt,
                          // width: profession === "student" ? "170px" : "100%",
                        }}
                        value={profession}
                        {...register('profession')}
                        onChange={handleProfessionChange}
                        // sx={{ width: '100%' }}
                      >
                        <MenuItem value="student">Student</MenuItem>
                        <MenuItem value="non-student">Non-Student</MenuItem>
                      </Select>
                      <Typography color="error">
                        {errors.profession?.message}
                      </Typography>
                    </Box>{' '}
                    {profession === 'student' && (
                      <Box>
                        <Typography sx={Styles.subText}>
                          Select Collage
                        </Typography>
                        <Select
                          sx={{ ...Styles.opt, width: '165px' }}
                          value={ipadd}
                          {...register('ipaddress')}
                          onChange={handleIPaddChange}
                          // sx={{ width: '100%' }}
                        >
                          <MenuItem value="unada">UNADA </MenuItem>
                        </Select>
                        {/* <Typography color="error">
                          {errors.ipaddress?.message}
                        </Typography> */}
                      </Box>
                    )}
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        'aria-labelledby': 'fade-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                    >
                      <MenuItem onClick={handleClose}>Profile</MenuItem>
                      <MenuItem onClick={handleClose}>My account</MenuItem>
                      <MenuItem onClick={handleClose}>Logout</MenuItem>
                    </Menu>
                  </Box>
                  {profession === 'student' && (
                    <Box sx={{}}>
                      <label
                        htmlFor="fileInput"
                        style={{
                          fontWeight: 600,
                          cursor: 'pointer',
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            color: 'rgba(115, 115, 115, 1)',
                            // border: '1.5px dashed  rgba(209, 209, 209, 1)',
                            border: '2px dashed rgba(209, 209, 209, 1)',
                            height: 'auto',
                            p: 0.5,
                            borderRadius: '10px',
                          }}
                          // onDragOver={handleDragOverMultiple}
                          // onDrop={handleDropMultiple}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              alignContent: 'center',
                              // gap: 1,
                              mt: 0.5,
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*,application/pdf"
                              id="fileInput"
                              style={{ display: 'none' }}
                              {...register('proof')}
                              // onChange={(e}
                            />
                            <Typography>Upload</Typography>
                          </Box>
                        </Box>
                        <Box mt={0}>
                          {fileNames.length > 0 && (
                            <Typography variant="body2">
                              {fileNames.join(', ')}
                            </Typography>
                          )}
                        </Box>
                        {/* Display file names here */}
                      </label>
                    </Box>
                  )}
                  <Typography sx={Styles.subText}>Choose Password</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      mx: 'auto',
                    }}
                  >
                    <Input
                      id="standard-basic"
                      variant="outlined"
                      disableUnderline
                      type={showPassword ? 'text' : 'password'}
                      {...register('password')}
                      placeholder="Minimum 8 characters"
                      sx={Styles.InputFieldDes}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box
                            component="img"
                            src={lock}
                            sx={Styles.iconStyle}
                          />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPasswordClick}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{
                        style: {
                          color: '#797979',
                          fontSize: '14px',
                          padding: '4px',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                        },
                      }}
                    />
                    <Typography sx={Styles.validationText}>
                      {errors.password?.message}
                    </Typography>
                  </Box>

                  <Button
                    type="submit"
                    sx={{
                      ...Styles.signInBtn,
                      mt: { xl: 2, lg: 2, md: 1, sm: 0.5, xs: 0.5 },
                    }}
                  >
                    Join now
                  </Button>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      justifyContent: 'center',
                    }}
                  >
                    {/* <Box sx={Styles.regButton}>
                      <Box
                        component={'img'}
                        src={ipimg}
                        alt=""
                        sx={{ height: 'auto', width: '20px' }}
                      />
                      &nbsp; Static IP Address
                    </Box> */}
                    <Box sx={Styles.regButton} onClick={login}>
                      <Box
                        component={'img'}
                        src={gglreg}
                        alt=""
                        sx={{ height: 'auto', width: '20px' }}
                      />
                      &nbsp;Login with Google
                    </Box>
                  </Box>
                  {/* <Box sx={Styles.LogInBtn} onClick={login}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={googleimg}
                        sx={{
                          height: "auto",
                          width: {
                            xl: "25px",
                            lg: "25px",
                            md: "25px",
                            sm: "20px",
                            xs: "15px",
                          },
                        }}
                      />
                      Login with Google
                    </Box>
                  </Box> */}
                  {/* <GoogleLogin
                  onSuccess={async (credentialResponse) => {
                    GoogleLoginMutate(credentialResponse.credential, {
                      onSuccess: () => {},
                      onError: () => {},
                    });
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                /> */}
                </Box>
              </form>
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SignUp;

const headerStyle = {
  alignItems: 'center',
  height: '80px',
  transition: 'right 2s',
  color: 'white',
  fontSize: '30px',
  fontWeight: 'bold',
  fontFamily: 'Poppins',
  width: '80%',
  mx: 'auto',
  position: 'absolute',
  top: 0,
};
